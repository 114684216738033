.whyJoin_wrapper {
    height: 100%;
    background: #1A1F27 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 8em 0 8em 0;
}

.heading {
    text-align: left;
    font: Bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
}

.mobileGrid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileImage {
    width: 500px;
    height: 450px;
    /* object-fit: cover;
    object-position: center center */
}

@media screen and (min-width: 991px) and (max-width: 1024px) {
    .mobileImage {
        width: 500px;
        height: 450px;
    }
}

@media screen and (max-width: 478px) {
    .mobileGrid {
        display: none;
    }
    .whyJoin_wrapper {
        padding: 5em 1em;
    }
}