.headerOuterDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  pointer-events: none;
  z-index: 1500;
  position: fixed;
  top: 0;
  background-color: white;
}

.header {
  width: 75%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.headerBackgroundWhite {
  width: 100%;
  height: 68px;
  background-color: white;
  position: absolute;
}
.logo {
  width: 111px;
  height: 28px;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 10;
  pointer-events: all;
}

.features {
  width: 160px;
  height: 19px;
  font: normal normal normal 14px Nunito Sans;
  letter-spacing: 0px;
  color: #1a172d;
  opacity: 0.6;
  cursor: pointer;
  pointer-events: all;
  position: relative;
  z-index: 10;
}

.borderNone {
  border: none;
}

.fixed {
  position: fixed;
}
