.sectionWrapper {
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 8em 5em;
}

.paragraph_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.paragraph_content p {
    text-align: center;
    font: 400 22px/45px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.6;
}

.paragraph_content p:nth-child(2) {
    padding-top: 1em;
}
.paragraph_content p:nth-child(3) {
    padding-top: 1em;
}

.button_wrapper {
    background: #4842DC 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 330px;
    height: 58px;
    margin-top: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

}

.button_content {
    text-align: center;
    font: 500 22px/33px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

}

@media screen and (max-width: 991px) {
    .sectionWrapper {
        padding: 8em 0;
    }
    .paragraph_content p {
        font: 400 20px/32px Poppins;
    }
    .button_wrapper {
        width: 315px;
        height: 55px;
    }
    
    .button_content {
        font: 500 20px/32px Poppins;
    }
}

@media screen and (max-width: 478px) {
    .sectionWrapper {
        padding: 5em 1em;
    }
    .paragraph_content p {
        text-align: left;
        font: 400 14px/28px Poppins;
        letter-spacing: 0px;
        color: #1A1F27;
    }
    .button_wrapper {
        width: 307px;
        height: 48px;
    }
    
    .button_content {
        font: 500 16px/25px Poppins;
    }
}