.showcase {
    background: #1A1F27 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    height: 92vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 0 20px; */
    margin-top: 8vh;
    /* border-top: 1px solid #1A1F27; */
}

.showcase .heading {
    font: Bold 100px/82px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 1;
}

.showcase .subtitle {
    font: 400 25px/45px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.6;
    padding: 50px 30%;
}

.scroll_down {
    position: absolute;
    top: 75vh;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width :25px;
    height: 45px;
  }
  .mousey {
    width: 2px;
    padding: 5px 10px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
  .scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }

  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }

.scroll_info {
    position: absolute;
    /* top: 75vh; */
    right: 0;
    bottom: 5%;
    left: 0;
    margin: auto;
}

.scroll_text {
    text-align: center;
    font: 400 12px/18px Poppins;
    letter-spacing: 3.12px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 0.3;
}

@media screen and (max-width: 991px) {
    .showcase .heading {
        font: Bold 84px/65px Poppins;
    }
    .showcase .subtitle {
        font: 400 24px/32px Poppins;
        padding: 50px 25%;
    }
}

@media screen and (max-width: 478px) {
    .showcase {   
        height: 70vh;
    }
    .showcase .heading {
        font: Bold 55px/82px Poppins;
    }
    .showcase .subtitle {
        font: 400 16px/28px Poppins;
        padding: 25px 18%;
    }
    .scroll_down {
        position: absolute;
        top: 42vh;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width :22px;
        height: 35px;
    }
    .mousey {
        width: 2px;
        padding: 1px 8px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 25px;
        opacity: 0.75;
        box-sizing: content-box;
    }
    .scroll_info {
        display: none;
    }
}