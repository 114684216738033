._modal {
    max-width: 600px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: auto !important;
    padding: 24px !important;
}

._paper {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    padding: 16px 40px 40px !important;
    box-sizing: border-box !important;
    width: 100% !important
}

.heading {
    text-align: left;
    padding: 16px 0px 0px 16px;
    font: Bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    text-transform: capitalize;
    opacity: 1;
}

.paragraph {
    text-align: left;
    padding: 10px 16px;
    font: 400 22px/45px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.6;
}

.btn_wrapper {
    padding: 16px 0px 0px 16px;
}

.share_Button {
    background: transparent !important;
    border: 1px solid #B0B6C1 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    width: 307px;
    height: 48px;
    font: 500 17px/26px Poppins !important;
    letter-spacing: 0px !important;
    color: #1A1F27 !important;
}

.share_Button:focus {
    outline: none;
}