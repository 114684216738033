.value_wrapper {
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 8em 0 8em 0;
}

@media (max-width: 478px) {
    .value_wrapper {
        padding: 5em 1em;
    }
}