.drawerHeading {
    text-align: left;
    font: Bold 24px/35px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    text-transform: capitalize;
    opacity: 1;
}

.formContent {
    margin-top: 35px;
}

.error {
    text-align: left;
    font: 500 12px/18px Poppins;
    letter-spacing: 1.2px;
    color: #FA6F45;
    text-transform: uppercase;
    opacity: 1;
}

.inputError {
    border: 1px solid #FA6F45;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
}

.form_label {
    text-align: left;
    font: 400 12px/18px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    text-transform: capitalize;
    opacity: 1;
}

:-moz-placeholder {
    text-align: left;
    font: 400 13px/20px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.4;
}

:-ms-input-placeholder {
    text-align: left;
    font: 400 13px/20px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.4;
}

::-webkit-input-placeholder {
    text-align: left;
    font: 400 13px/20px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.4;
}

.buttonWrapper {
    background: #4842DC 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    font: 500 17px/26px Poppins !important;
    letter-spacing: 0px !important;
    color: #FFFFFF !important;
    opacity: 1;
    width: 100%;
    height: 43px;
    text-transform: capitalize !important;
    margin-top: 35px !important;
}

.buttonWrapper:focus {
    outline: none;
}