.newPrivacyPolicy {
  width: 100%;
  margin-bottom: 400px;
}

/* .content2 {
  text-align: left;
  font: 400 14px/22px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.65;
  margin-bottom: 0.5em;
} */

.heading2New {
  text-align: left;
  font: Bold 24px/32px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.heading3New {
  text-align: left;
  font: 600 16px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.heading4 {
  text-align: left;
  font: 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.headingTopp {
  text-align: left;
  font: Bold 32px/48px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
