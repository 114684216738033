.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #1A1F27 0% 0% no-repeat padding-box;
    height: 8vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 1rem; */
}

.toolbar__logo a {
    text-align: left;
    font: Bold 27px/40px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    text-decoration: none;
}

.toolbar__logo a:hover {
    text-decoration: none;
    color: #ffffff;
}

.toolbar__navigation_items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar__navigation_items li {
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbar__navigation_items .mail_id {
    font: 500 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.6;
}

.toolbar__navigation_items .button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.toolbar__navigation_items .button .button_text {
    font: 500 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 1;
    text-transform: capitalize;
}

.access_button {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    font: 500 14px/21px Poppins !important;
    text-transform: capitalize !important;
    letter-spacing: 0px !important;
    color: #1A1F27 !important;
}

.access_button:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
}

.access_button:focus {
    outline: none;
}

.mobile_access_button {
    border: 1px solid #434B58 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    background: transparent !important;
    font: 500 14px/21px Poppins !important;
    letter-spacing: 0px !important;
    text-transform: capitalize !important;
    color: #FFFFFF !important;
}

.mobile_access_button:hover {
    background: transparent !important;
}

.mobile_access_button:focus {
    outline: none;
}

@media (min-width: 992px) {
    .navigation__toggle_button {
        display: none;
    }
    .mobile_access_btn_wrapper {
        display: none;
    }
}

@media (max-width: 991px) {
    .navigation__toggle_button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .toolbar__navigation_items li {
        padding: 1.5rem 0;
    }
    .toolbar__navigation .toolbar__navigation_items {
        position: absolute;
        width: 100%;
        height: calc(100vh - 8vh);
        background-color: #333;
        top: 8vh;
        left: -100%;
        transition: 0.5s;
    }
    .toolbar__navigation .toggle_menu {
        left: 0px;
    }
    .toolbar__navigation_items ul {
        display: block;
    }
}

@media (max-width: 478px) {
    .toolbar__logo a {
        font: Bold 20px/30px Poppins;
    }
}