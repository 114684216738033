.modal {
    max-width: 600px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: auto !important;
    padding: 24px !important;
}
.paper {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
    padding: 16px 40px 40px !important;
    box-sizing: border-box !important;
    width: 100% !important
}

.modalHeading {
    text-align: left;
    font: Bold 30px/46px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    text-transform: capitalize;
    opacity: 1;
}

.error {
    text-align: left;
    font: 500 12px/18px Poppins;
    letter-spacing: 1.2px;
    color: #FA6F45;
    text-transform: uppercase;
    opacity: 1;
}

.inputError {
    border: 1px solid #FA6F45;
}

.formContent {
    margin-top: 40px;

}

.form_label {
    text-align: left;
    font: 400 12px/18px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    text-transform: capitalize;
    opacity: 1;
}

input {
    width: 100%;
    background: #F0F0F0 0% 0% no-repeat padding-box;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    opacity: 1;
    text-align: left;
    font: 400 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    /* opacity: 0.4; */
}



.inputWrapper {
    display: flex;
    flex-direction: column;
}

input:focus {
    outline: none;
}

:-moz-placeholder {
    text-align: left;
    font: 400 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.4;
}

:-ms-input-placeholder {
    text-align: left;
    font: 400 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.4;
}

::-webkit-input-placeholder {
    text-align: left;
    font: 400 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 0.4;
}

.buttonWrapper {
    background: #4842DC 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    font: 500 16px/25px Poppins !important;
    letter-spacing: 0px !important;
    color: #FFFFFF !important;
    opacity: 1;
    width: 136px;
    height: 40px;
    text-transform: capitalize !important;
    margin-top: 40px !important;
}

.buttonWrapper:focus {
    outline: none;
}

@media screen and (max-width: 500px) {
    .modalHeading {
        font: Bold 18px/33px Poppins;
    }
    .formContent {
        margin-top: 10px;
    }
    
    input[type=text] {
        width: 100%;
    }
}