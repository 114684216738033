.footer_wrapper {
    background: #1A1F27 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100%;
    padding: 4em 0 2em 0;
}

@media (max-width: 478px) {
    .footer_wrapper {
        padding: 2em 0;
    }
}