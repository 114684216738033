.heading {
    text-align: left;
    font: Bold 24px/20px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 1;
}

.paragraph {
    text-align: left;
    font: 400 16px/28px Poppins;
    letter-spacing: 0px;
    color: #1A1F27;
    opacity: 1;
    padding-top: 15px;
}

.share_Button {
    border: 1px solid #B0B6C1 !important;
    background: transparent !important;
    border-radius: 4px !important;
    font: 500 17px/26px Poppins !important;
    letter-spacing: 0px !important;
    color: #1A1F27 !important;
    opacity: 1;
    width: 100%;
    /* height: 24px; */
    text-transform: capitalize !important;
    margin-top: 20px !important;
    margin-bottom: 10px;
}

.share_Button:focus {
    outline: none;
}